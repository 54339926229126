<template>
    <div class="PlanEdit" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never">
            <el-page-header @back="goBack" />
        </el-card>

        <el-form
            @submit.native.prevent
            :inline="true"
            :model="plan"
            ref="editForm"
            size="small"
            label-width="100px"
            :rules="rules"
        >
            <el-card shadow="never" style="margin-top: 8px">
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="方案名称" prop="name">
                            <el-input v-model="plan.name" placeholder="请输入方案名称" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="促销机构">
                            <el-select
                                v-model="plan.deptCode"
                                ref="deptSelect"
                                @change="plan.tableData = []"
                                filterable
                            >
                                <el-option
                                    v-for="dept in meta.depts"
                                    :label="dept.name"
                                    :value="dept.code"
                                    :key="dept.code"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="促销类型">
                            <el-select v-model="plan.ruleType" @change="queryRuleCode">
                                <el-option :value="1" label="单商品直接折扣" />
                                <el-option :value="2" label="单商品偶数折扣" />
                                <el-option :value="3" label="单商品直接特价" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="查询条件">
                            <el-input placeholder="名称/条码" v-model="queryData.search" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="创建人">
                            <el-input v-model="$store.state.session.name" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="创建日期">
                            <el-date-picker
                                style="width: 200px"
                                v-model="meta.today"
                                value-format="yyyy-MM-dd"
                                readonly
                            />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="促销时间段">
                            <el-date-picker
                                v-model="plan.planDateArr"
                                type="daterange"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                value-format="yyyy-MM-dd"
                                :clearable="false"
                                :editable="false"
                                unlink-panels
                                :picker-options="datePickerOptions"
                                @change="changeWeekdays"
                            />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="促销日">
                            <el-checkbox-group v-model="plan.weekdays">
                                <el-checkbox :label="1" :disabled="enabledWeekOption(1)">周一</el-checkbox>
                                <el-checkbox :label="2" :disabled="enabledWeekOption(2)">周二</el-checkbox>
                                <el-checkbox :label="3" :disabled="enabledWeekOption(3)">周三</el-checkbox>
                                <el-checkbox :label="4" :disabled="enabledWeekOption(4)">周四</el-checkbox>
                                <el-checkbox :label="5" :disabled="enabledWeekOption(5)">周五</el-checkbox>
                                <el-checkbox :label="6" :disabled="enabledWeekOption(6)">周六</el-checkbox>
                                <el-checkbox :label="7" :disabled="enabledWeekOption(7)">周日</el-checkbox>
                            </el-checkbox-group>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="作用群体">
                            <el-checkbox-group v-model="plan.checkList" @change="selectMemberScopeFlag">
                                <el-checkbox :label="1">会员</el-checkbox>
                                <el-checkbox :label="2">非会员</el-checkbox>
                            </el-checkbox-group>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>
            <el-card shadow="never" style="margin-top: 8px; text-align: left">
                <el-button type="primary" @click="queryAndAdd" size="small">查询并添加</el-button>
                <el-button type="primary" @click="handleSelectGoods" size="small">选择商品基本信息</el-button>
                <el-button type="primary" @click="handleSave" size="small">保存</el-button>
            </el-card>
            <el-card shadow="never" style="margin-top: 8px" align="left">
                <el-table
                    id="printMe"
                    border
                    stripe
                    style="width: 100%"
                    :data="plan.tableData"
                    size="mini"
                    :highlight-current-row="true"
                    max-height="500"
                >
                    <el-table-column label="序号" type="index" width="60" align="center" fixed="left" />
                    <el-table-column prop="sku.name" label="商品名称" min-width="180" />
                    <el-table-column label="条码" width="180">
                        <template slot-scope="scope">
                            <span v-for="b in scope.row.sku.bars" :key="b">{{ b }}<br /></span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="sku.specs" label="规格" min-width="100" />
                    <el-table-column prop="sku.category" label="类目" width="100" />
                    <el-table-column prop="sku.unit" label="单位" width="80" />
                    <el-table-column prop="sku.brand" label="品牌" width="100" />
                    <el-table-column prop="retailPrice" label="当前零售价" width="100">
                        <template slot-scope="scope">
                            <span>{{ scope.row.retailPrice | money }}</span>
                        </template>
                    </el-table-column>
                    <template v-if="form.ruleType == 1">
                        <el-table-column label="折扣率" width="100" :key="1">
                            <template slot-scope="scope">
                                <el-form-item
                                    class="tab-form"
                                    :prop="'tableData.' + scope.$index + '.discounts'"
                                    :rules="rules.discount"
                                >
                                    <el-input-number
                                        :controls="false"
                                        :max="10"
                                        :min="0"
                                        :precision="1"
                                        v-model="scope.row.discounts"
                                    />
                                </el-form-item>
                            </template>
                        </el-table-column>
                        <el-table-column label="折后价" width="100" :key="2">
                            <template slot-scope="scope">
                                <span>{{ scope.row.discounts | discountMoney(scope.row.retailPrice) }}</span>
                            </template>
                        </el-table-column>
                    </template>
                    <template v-if="form.ruleType == 2">
                        <el-table-column label="偶数折扣率" width="100" :key="1">
                            <template slot-scope="scope">
                                <el-form-item
                                    class="tab-form"
                                    :prop="'tableData.' + scope.$index + '.discounts'"
                                    :rules="rules.discount"
                                >
                                    <el-input-number
                                        :controls="false"
                                        :max="10"
                                        :min="0"
                                        typeof="number"
                                        :precision="1"
                                        v-model="scope.row.discounts"
                                    />
                                </el-form-item>
                            </template>
                        </el-table-column>
                        <el-table-column label="偶数折后价" width="100" :key="2">
                            <template slot-scope="scope">
                                <span>{{ scope.row.discounts | discountMoney(scope.row.retailPrice) }}</span>
                            </template>
                        </el-table-column>
                    </template>
                    <template v-if="form.ruleType == 3">
                        <el-table-column label="特惠价" width="100">
                            <template slot-scope="scope">
                                <ef-price-input v-model="scope.row.favourPrices" :precision="2" />
                            </template>
                        </el-table-column>
                    </template>
                    <el-table-column label="操作" width="180">
                        <template slot-scope="scope">
                            <el-button size="mini" type="danger" @click="handleDelete(scope.$index)">删除 </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-card>
        </el-form>
        <pickUpGoods ref="pickUpGoods" @queryGoods="handleSelectGoodsOk" />
    </div>
</template>

<script>
import UrlUtils from 'js/UrlUtils';
import Util from 'js/Util';
import PickUpGoods from 'components/PickUpGoods';
import MoneyUtils from 'js/MoneyUtils';
import EfPriceInput from 'components/EfPriceInput';
import qs from 'qs';
import Decimal from 'decimal';
import { discountValidateRule } from 'js/validate/ValidateCommonRule';
import { DeptTypeEnum } from 'js/DeptCommon';

export default {
    name: 'PlanEdit',
    components: { PickUpGoods, EfPriceInput },
    props: ['form'],
    data() {
        return {
            plan: {
                allGoodsFlag: false,
                deptCode: '',
                ruleType: 1,
                ruleCode: '',
                memberScopeFlag: '',
                name: '',
                startTime: '',
                endTime: '',
                weekdays: [],
                checkList: [],
                planDateArr: [],
                tableData: [],
            },
            codesBackUp: [],
            datePickerOptions: {
                disabledDate: (time) => {
                    //不能小于当前日期
                    return time.getTime() < Date.now() - 24 * 60 * 60 * 1000;
                },
            },
            url: {
                goods: '/goods/goods/list_c',
                batchSave: '/promotion/patchExtend/',
                queryRuleCode: '/promotion/rule',
            },
            meta: {
                enabledWeekDays: [],
                today: new Date(),
                depts: [],
            },

            queryData: {
                search: '',
                deptCode: '',
                goodsStatusIn: 1,
            },

            rules: {
                name: [{ required: true, message: '请输入方案名称', trigger: 'blur' }],
                discount: discountValidateRule(),
            },
        };
    },
    mounted() {
        this.inits();
    },
    watch: {
        'plan.planDateArr': {
            handler(n, o) {
                //解构赋值
                [this.plan.startTime, this.plan.endTime] = n;
            },
            immediate: true,
        },
    },

    methods: {
        enabledWeekOption(day) {
            return (
                this.meta.enabledWeekDays.filter((r) => {
                    return r == day;
                }).length <= 0
            );
        },
        inits() {
            this.$efApi.deptApi.managedDeptsByDeptType(DeptTypeEnum.STORE).then((rst) => {
                this.meta.depts = rst;
            });
            const _this = this;
            Object.assign(this.plan, this.form);
            this.queryRuleCode(this.plan.ruleType);
            if (_this.form.memberScopeFlag == 2) {
                _this.plan.checkList = [1, 2];
            } else if (_this.form.memberScopeFlag == 1) {
                _this.plan.checkList = [1];
            } else if (_this.form.memberScopeFlag == 0) {
                _this.plan.checkList = [2];
            }
            this.plan.planDateArr = [this.form.startDate, this.form.endDate];
            this.changeWeekdays(this.plan.planDateArr);
            _this.plan.weekdays = [];
            if (_this.form.weekdays.indexOf(',') >= 0) {
                let dayArr = [];
                (async () => {
                    await (dayArr = _this.form.weekdays.split(',').map(Number));
                    _this.plan.weekdays = dayArr;
                })();
            } else {
                _this.plan.weekdays.push(Number(_this.form.weekdays));
            }
            this.handleQuery();
        },

        handleQuery() {
            const _this = this;
            UrlUtils.QueryRemote(this, '/promotion/info_c/' + this.form.code, (rst) => {
                this.codesBackUp = rst.codes;
                UrlUtils.QueryRemote(
                    _this,
                    this.url.goods + '?' + qs.stringify({ codes: rst.goodsCodes }, { indices: false }),
                    (data) => {
                        (async () => {
                            await data.forEach((v, i) => {
                                v.discounts = rst.discounts[i];
                                v.codes = rst.codes[i];
                                v.favourPrices = MoneyUtils.moneyToYuan(rst.favourPrices[i]);
                            });
                            _this.plan.tableData = data;
                        })();
                    }
                );
            });
        },
        selectMemberScopeFlag: function () {
            const _this = this;
            if (_this.plan.checkList.length == 2) {
                _this.plan.memberScopeFlag = 2;
            } else {
                if (_this.plan.checkList[0] == 1) {
                    _this.plan.memberScopeFlag = 1;
                } else {
                    _this.plan.memberScopeFlag = 0;
                }
            }
        },
        changeWeekdays(planDateArr) {
            const startTime = planDateArr[0];
            const endTime = planDateArr[1];
            const _this = this;
            if (Util.isEmpty(startTime) || Util.isEmpty(endTime)) {
                return;
            }
            const endTimeArr = endTime.split('-'); //获取第一个数组的值
            const endTime1 = new Date(endTimeArr[0], endTimeArr[1], endTimeArr[2]); //将前半个数组以-拆分，每一个是一个数值
            const startTimeArr = startTime.split('-');
            const startTime1 = new Date(startTimeArr[0], startTimeArr[1], startTimeArr[2]);
            const iDays = parseInt(Math.abs(endTime1 - startTime1) / 1000 / 60 / 60 / 24) + 1; //把相差的毫秒数转换为天数
            let dayArr = [];
            if (iDays > 6) {
                dayArr = [1, 2, 3, 4, 5, 6, 7];
            } else {
                const dataArr = _this.getData(startTime, endTime);
                dayArr = dataArr.map((r) => {
                    const a = new Date(r).getDay();
                    return !a ? 7 : a;
                });
            }
            this.plan.weekdays = JSON.parse(JSON.stringify(dayArr));
            this.meta.enabledWeekDays = JSON.parse(JSON.stringify(dayArr));
            return iDays;
        },
        getData(startTime, endTime) {
            // eslint-disable-next-line no-extend-native
            Date.prototype.format = function () {
                let s = '';
                const mouth = this.getMonth() + 1 >= 10 ? this.getMonth() + 1 : '0' + (this.getMonth() + 1);
                const day = this.getDate() >= 10 ? this.getDate() : '0' + this.getDate();
                s += this.getFullYear() + '-'; // 获取年份。
                s += mouth + '-'; // 获取月份。
                s += day; // 获取日。
                return s; // 返回日期。
            };

            function getAll(begin, end) {
                const arr = [];
                const ab = begin.split('-');
                const ae = end.split('-');
                const db = new Date();
                db.setUTCFullYear(ab[0], ab[1] - 1, ab[2]);
                const de = new Date();
                de.setUTCFullYear(ae[0], ae[1] - 1, ae[2]);
                const unixDb = db.getTime() - 24 * 60 * 60 * 1000;
                const unixDe = de.getTime() - 24 * 60 * 60 * 1000;
                for (let k = unixDb; k <= unixDe; ) {
                    k = k + 24 * 60 * 60 * 1000;
                    arr.push(new Date(parseInt(k)).format());
                }
                return arr;
            }

            return getAll(startTime, endTime);
        },
        handleSave() {
            const _this = this;
            let validDiscounts = false;
            let validPrices = false;
            if (Util.isEmpty(this.plan.startTime) || Util.isEmpty(this.plan.endTime)) {
                _this.$message.error('请选择开始时间和结束时间');
                return;
            }
            if (!this.plan.weekdays.length) {
                _this.$message.error('请勾选促销日');
                return;
            }
            if (!this.plan.checkList.length) {
                _this.$message.error('请勾选作用群体');
                return;
            }
            if (_this.plan.name == '') {
                _this.$message.error('请输入方案名称');
                return;
            }
            this.plan.tableData.forEach((value) => {
                if (typeof value.discounts == 'undefined') {
                    validDiscounts = true;
                }
                if (typeof value.favourPrices == 'undefined') {
                    validPrices = true;
                }
            });

            if (this.plan.deptCode == '') {
                this.$message.error('请选择机构');
                return;
            } else if (this.plan.tableData.length == 0) {
                this.$message.error('请选择至少一件商品');
                return;
            } else if (validDiscounts && _this.plan.ruleType != 3) {
                this.$message.error('请正确填写所有的折扣');
                return;
            } else if (validPrices && _this.plan.ruleType == 3) {
                this.$message.error('请正确填写所有的优惠价');
                return;
            } else {
                this.$refs.editForm.validate(async (valid) => {
                    if (!valid) {
                        return false;
                    }
                    const codes = _this.plan.tableData.map((d) => d.codes || ' ');
                    const _params = {
                        allGoodsFlag: this.plan.allGoodsFlag,
                        deptCode: this.plan.deptCode,
                        ruleType: this.plan.ruleType,
                        ruleCode: this.plan.ruleCode,
                        name: this.plan.name,
                        memberScopeFlag: this.plan.memberScopeFlag,
                        startTime: this.plan.startTime,
                        endTime: this.plan.endTime,
                        weekdays: this.plan.weekdays,

                        goodsCodes: _this.plan.tableData.map((d) => d.code || ' '),
                        codes: codes,
                        delCodes: _this.codesBackUp.filter((r) => !codes.includes(r)),
                        discounts: _this.plan.tableData.map((d) => (d.discounts == 0 ? 0 : d.discounts || '')),
                        favourPrices: _this.plan.tableData.map((d) =>
                            d.favourPrices == 0 ? 0 : MoneyUtils.moneyToDb(d.favourPrices) || ''
                        ),
                    };
                    await UrlUtils.PatchRemote(this, this.url.batchSave + this.form.code, _params, null, () => {
                        this.$message.success('保存成功');
                        this.goBackAndReload();
                    });
                });
            }
        },
        queryAndAdd() {
            const _this = this;
            const disable = _this.plan.deptCode != '' && _this.plan.ruleType != '';
            if (!disable) {
                _this.$message.error('请选择促销机构');
                return;
            }
            if (!_this.plan.search.trim()) {
                _this.$message.error('请输入查询条件');
                return;
            }
            const _params = { params: { search: this.plan.search, ...this.pickUpGoodsQueryParam() } };
            Util.queryTable(_this, _this.url.queryGoods, _params, (data) => {
                if (data.status == 200) {
                    _this.handleSelectGoodsOk(data.data);
                }
            });
        },

        handleSelectGoods() {
            const _this = this;
            const disable = _this.plan.deptCode != '' && _this.plan.ruleType != '';
            if (!disable) {
                _this.$message.error('请选择促销机构');
                return;
            }
            const deptName = this.form.deptName;
            this.$refs.pickUpGoods.show(this.pickUpGoodsQueryParam(), deptName);
        },
        handleQueryGoods() {
            const _this = this;
            UrlUtils.QueryRemote(this, '/promotion/info_c/' + this.form.code, (rst) => {
                UrlUtils.QueryRemote(
                    _this,
                    this.url.goods + '?' + qs.stringify({ codes: rst.goodsCodes }, { indices: false }),
                    (data) => {
                        _this.plan.tableData = data;
                        _this.plan.tableData.forEach((v, i) => {
                            v.discounts = rst.discounts[i];
                            v.codes = rst.codes[i];
                            v.favourPrices = MoneyUtils.moneyToYuan(rst.favourPrices[i]);
                        });
                    }
                );
            });
        },
        pickUpGoodsQueryParam() {
            return { deptCode: this.plan.deptCode, goodsStatusIn: [1] };
        },

        queryRuleCode(type) {
            this.plan.tableData = [];
            UrlUtils.QueryRemote(this, this.url.queryRuleCode + '?type=' + type, (rst) => {
                this.plan.ruleCode = rst.code;
            });
        },

        handleSelectGoodsOk(data) {
            let _a = this.plan.tableData;
            data.forEach((v) => {
                _a.push(v);
            });
            _a = this.duplicate(_a, 'skuCode');
            this.plan.tableData = _a;
        },

        handleDelete(index) {
            const _a = this.plan.tableData;
            _a.splice(index, 1);
            this.plan.tableData = _a;
        },
    },
    filters: {
        discountMoney(discount, prices) {
            if (discount) {
                const temDiscount = Decimal(discount).div(10);
                const temPrices = MoneyUtils.moneyToYuan(prices);
                return Decimal(temDiscount).mul(Decimal(temPrices)).toNumber().toFixed(2);
            } else {
                return '';
            }
        },
    },
};
</script>

<style scoped>
.el-input-number {
    width: auto;
}

.el-input-number >>> .el-input__inner {
    text-align: left;
}

.tab-form >>> .el-form-item__error {
    position: relative;
    left: 0;
    line-height: 1;
}
</style>
